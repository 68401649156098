import React, { useEffect, useState } from "react";
import { Table, Tabs } from "antd";
import Layout from "../components/Layout";
import {
  cancelBooking,
  checkoutBooking,
  fetchCustomers,
  fetchPastBookingData,
  fetchUpcomingBookingData,
} from "../modules/LandingPage";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const Booking = () => {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [upcomingBooking, setUpcomingBooking] = useState();
  const [pastBooking, setPastBooking] = useState();
  const [customers, setCustomers] = useState([]);

  const navigate = useNavigate();

  const getUpcomingBookingData = async () => {
    try {
      const costCenter = localStorage.getItem("costCenter");
      const response = await fetchUpcomingBookingData(costCenter);
      console.log(response);
      const processedData = response.data.message.map((booking) => {
        // Extract table numbers from the booked_tables array and join them into a comma-separated string
        const table_nos = booking.booked_tables
          .map((table) => table.table_no)
          .join(", ");

        // Combine booking_date and booking_time into a space-separated string
        const date_and_time = `${booking.booking_date} ${booking.booking_time}`;

        // Return the modified booking object with the new table_nos and date_and_time fields
        return {
          ...booking, // Keep all existing properties
          table_nos, // Add the new table_nos field
          date_and_time, // Add the new date_and_time field
        };
      });
      setUpcomingBooking(processedData);
    } catch (error) {
      console.log("Failed to load table data.", error);
    } finally {
    }
  };
  const getPastBookingData = async () => {
    try {
      const costCenter = localStorage.getItem("costCenter");
      const response = await fetchPastBookingData(costCenter);
      console.log(response);
      const processedData = response.data.message.map((booking) => {
        // Extract table numbers from the booked_tables array and join them into a comma-separated string
        const table_nos = booking.booked_tables
          .map((table) => table.table_no)
          .join(", ");

        // Combine booking_date and booking_time into a space-separated string
        const date_and_time = `${booking.booking_date} ${booking.booking_time}`;

        // Return the modified booking object with the new table_nos and date_and_time fields
        return {
          ...booking, // Keep all existing properties
          table_nos, // Add the new table_nos field
          date_and_time, // Add the new date_and_time field
        };
      });
      setPastBooking(processedData);
    } catch (error) {
      console.log("Failed to load table data.", error);
    } finally {
    }
  };
  const cancelBookinghandler = async (text, record) => {
    try {
      const temp = record;
      console.log(record);
      console.log(record.name);
      console.log(temp);

      const response = await cancelBooking(temp.name);
      console.log(response);
      getUpcomingBookingData();
      getPastBookingData();
    } catch (error) {
      console.log("Failed to load table data.", error);
    } finally {
    }
  };
  const chackOutBookinghandler = async (text, record) => {
    try {
      const temp = record;
      console.log(record);
      console.log(record.name);
      console.log(temp);

      const response = await checkoutBooking(
        temp.name,
        JSON.stringify(temp.booked_tables)
      );
      console.log(response);
      getUpcomingBookingData();
      getPastBookingData();
    } catch (error) {
      console.log("Failed to load table data.", error);
    } finally {
    }
  };

  useEffect(() => {
    getUpcomingBookingData();
    getPastBookingData();
  }, []);

  useEffect(() => {
    const loadCustomers = async () => {
      try {
        const customerData = await fetchCustomers();
        setCustomers(customerData);
      } catch (err) {
        console.log("error", err);
      }
    };

    loadCustomers();
  }, []);

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      className: "booking-column",
    },
    {
      title: "Contact No.",
      dataIndex: "customer_mobile_no",
      key: "customer_mobile_no",
      className: "booking-column",
    },
    {
      title: "Table No.",
      dataIndex: "table_nos",
      key: "table_nos",
      className: "booking-column",
    },
    {
      title: "No. of Guests",
      dataIndex: "no_of_guest",
      key: "no_of_guest",
      className: "booking-column",
    },
    {
      title: "Date & Time",
      dataIndex: "date_and_time",
      key: "date_and_time",
      className: "booking-column",
    },
    {
      title: "Action",
      dataIndex: "type",
      key: "type",
      className: "booking-column",
      render: (text, record) => (
        <span
          className={
            text === "Check IN" ? "booking-canael" : "booking-checkout"
          }
          onClick={() => {
            const temp = record;
            console.log(record);
            if (text === "Check IN") {
              cancelBookinghandler(text, temp);
            }

            if (text === "Check OUT") {
              chackOutBookinghandler(text, temp);
            }
          }}
        >
          {text === "Check IN" ? "Cancel" : "Check out"}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "type",
      key: "type",
      className: "booking-column",
      render: (text, record) => (
        <span
          className={
            text === "Check IN" ? "booking-checkin" : "booking-checkout"
          }
          onClick={() => {
            const temp = record;
            console.log(record);
            if (text === "Check IN") {
              localStorage.setItem("order_service_type", "Reservation");
              localStorage.setItem("booking_number", temp.name);
              localStorage.setItem(
                "table_no",
                JSON.stringify(temp.booked_tables)
              );
              const selectedcustomer = customers.filter((item) => {
                return item.name === temp.customer;
              });
              localStorage.setItem(
                "selectedCustomer",
                JSON.stringify(selectedcustomer[0])
              );
              navigate("/main");
            }
          }}
        >
          {text === "Check IN" ? "Check in" : ""}
        </span>
      ),
    },
  ];

  return (
    <Layout>
      <div className="booking-page">
        <h1 className="booking-title">ORDERS</h1>
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          className="booking-tabs"
        >
          <TabPane tab="UPCOMING BOOKINGS" key="upcoming">
            <Table
              columns={columns}
              dataSource={upcomingBooking}
              pagination={false}
              className="booking-table"
            />
          </TabPane>
          <TabPane tab="PAST BOOKINGS" key="past">
            <Table
              columns={columns}
              dataSource={pastBooking}
              pagination={false}
              className="booking-table"
            />
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  );
};

export default Booking;
